<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="option.id"
      class="flex items-center"
    >
      <div class="pt-1 pr-4 text-gray-400">
        <fa :icon="['far', 'square']" fixed-width />
      </div>
      <div>
        <sqr-input-text
          :value="option.label"
          @change="optionChange(index, $event)"
        />
      </div>
      <div class="pt-1 px-2">
        <sqr-button icon="trash" @click="optionRemove(index)" />
      </div>
    </div>
    <div class="mt-3">
      <sqr-button
        icon="plus"
        label="Add an option"
        color="blue"
        light
        @click="optionAdd()"
      />
    </div>
  </div>
</template>

<script>
import options from '../options';
import SqrInputText from '@/sqrd-ui/SqrInputText';

export default {
  name: 'CheckboxEdit',
  mixins: [options],
  components: { SqrInputText }
};
</script>
